import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store/vuexindex';
import Axios from 'axios';
import './assets/css/main.css';
import moment from 'moment';
import { createPinia } from 'pinia';
import { main } from './store/index';
// import VuePapaParse from 'vue-papa-parse';

import env from '../env.js';

const pinia = createPinia();
const app = createApp(App);

const baseUrl = env.baseUrl;

app.config.globalProperties.$http = Axios.create({
  baseURL: baseUrl,
  // timeout: 10000,
});

app.provide('$http', app.config.globalProperties.$http);
app.provide('$user', app.config.globalProperties.$user);
app.provide('$date', app.config.globalProperties.$date);
app.provide('$helpers', app.config.globalProperties.$helpers);
// console.log = function log() {};

// app.use(VuePapaParse);

const getFromLocal = (key, defaultVal) => {
  let value;
  if (localStorage[key]) {
    try {
      value = JSON.parse(localStorage[key]);
    } catch (e) {
      delete localStorage[key];
    }
  }
  return value || defaultVal;
};

app.config.globalProperties.$user = {
  logEvent(parameterName) {
    app.config.globalProperties.$gtag.event('select_content', {
      event_category: 'e-commerce',
      event_label: 'item_name',
      value: parameterName,
    });
  },
  googleInit() {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://accounts.google.com/gsi/client';
    document.head.appendChild(script);
    return script;
  },
  googleSignOut(isDifferentUser) {
    const script = this.googleInit();
    script.onload = () => {
      console.log('script loaded');
      // eslint-disable-next-line
      const localGapi = gapi;
      localGapi.load('auth2', () => {
        localGapi.auth2.init().then(() => {
          const auth2 = localGapi.auth2.getAuthInstance();
          if (isDifferentUser) {
            auth2.disconnect();
          }
          if (auth2.isSignedIn.get()) {
            auth2.signOut().then(() => {
              console.log('User signed out from google.');
            });
          }
        });
      });
    };
  },

  get user() {
    const value = getFromLocal('rx_new_user', {});
    if (!value) {
      localStorage.rx_new_user = JSON.stringify({});
    }
    return value;
  },
  set user(value) {
    localStorage.rx_new_user = JSON.stringify(value);
  },
  hasPermission(id) {
    return main().user?.permissions?.includes(id);
  },
  // isDifferentUser
  logout() {
    // this.googleSignOut(true); // hard-coding so that google login doesn't auto-login after logout
    localStorage.removeItem('rx_token');
    localStorage.removeItem('rx_new_user');
  },
};

app.config.globalProperties.$helpers = {
  IS_DEBUG: false,
  siteBaseUrl: env.siteBaseUrl,
  fileBaseUrl: env.fileBaseUrl,
  qrUrl: env.qrUrl,

  setKey(obj, key, defaultKey) {
    return obj?.[key] || defaultKey;
  },

  formatToLakh(value) {
    const number = parseInt(value);
    const lakh = (number / 100000).toFixed(2);
    return lakh + ' L';
  },

  formatToThousandK(value) {
    const number = parseInt(value);
    const k = (number / 1000).toFixed(2);
    return k + ' K';
  },

  getCommaFormatedNumber(number) {
    if (number) {
      number = number.toString();
      let lastThree = number.substring(number.length - 3);
      const otherNumbers = number.substring(0, number.length - 3);
      if (otherNumbers != '') lastThree = ',' + lastThree;
      const res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
      return res;
    }
  },

  regex: {
    password: /^.{6,}$/,
    firstname: /^[a-zA-Z -]{2,}$/,
    lastname: /^[a-zA-Z -]{1,}$/,
    fullname: /^[a-zA-Z -]{3,}$/,
    // eslint-disable-next-line
    email: /^[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
    mobile: /^[0-9/+]{10}$/, // exact 10 characters only
    generalText: /^.{3,}$/,
    housename: /^[a-zA-Z0-9 -]{3,}$/,
    street: /^[a-zA-Z0-9 -]{3,}$/,
    message: /^.{3,}$/,
    shortText: /^.{1,}$/,
    pin: /^[0-9 ]{6}$/,
    city: /^[a-zA-Z& ]{3,}$/,
    state: /^[a-zA-Z& ]{3,}$/,
    country: /^[a-zA-Z ]{2,}$/,
    number: /^[0-9]{1,}$/,
    floatingNumber: /^[+-]?([0-9]*[.])?[0-9]+$/,
    vitalleoQty: /^([1-9]|[1-4][0-9]|50)$/,
  },
};

app.config.globalProperties.$cache = {};

app.config.globalProperties.$date = {
  getTimeHuman(dateTime) {
    return moment(dateTime).format('hh:mm:ss a');
  },
  getDateTime(dateTimeStringTZ) {
    let date = new Date(dateTimeStringTZ).toString();
    return date.substring(4, 15);
  },
  getDateWithTime(dateTimeStringTZ) {
    let date = new Date(dateTimeStringTZ).toString();
    return date.substring(4, 21);
  },
};

// new Vue({
//   router,
//   render: (h) => h(App),
//   store,
//   data: {
//     // ////////////////////////
//     // tv app
//     // ////////////////////////
//     cartMap: {},
//     cart: [],
//     prodUrl: '',
//     // ////////////////////////
//     // rx admin
//     // ////////////////////////
//     isLoginModalOpen: false,
//     isLoadingName: false,
//     isConfirmModalOpen: false,
//     selectedObject: {},
//     selectedUser: {},
//     openedModal: '',
//     isSideMenuOpen: false,
//     token: '',
//     user: {},
//     order: {},
//     toastMsg: '',
//     isOpen: false,
//     randomNumber(min, max) {
//       return Math.floor(Math.random() * (max - min) + min);
//     },
//     toast(msg) {
//       this.toastMsg = msg;
//       setTimeout(() => {
//         this.toastMsg = '';
//       }, 2000);
//     },
//   },
// }).$mount('#app');

// app.use(store).use(router).mount('#app');
app.use(pinia).use(store).use(router).mount('#app');
