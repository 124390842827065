module.exports = {
  hasPermission(user, permId) {
    console.log('hasPermission', user);
    if (Object.keys(user).length !== 0) {
      return user?.permissions.includes(permId);
    } else {
      return false;
    }
  },

  isAdmin(user) {
    return user.isA;
  },
};
