<template>
  <svg viewBox="0 0 18 19" class="h-5 w-5 fill-current inline-block">
    <title>Account</title>
    <g id="Symbols" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g id="mainMenu" transform="translate(-351.000000, 0.000000)">
        <path
          d="M362,11 C365.859,11 369,14.141 369,18 L369,18 L369,19 L367,19 L367,18 C367,15.243 364.757,13 362,13 L362,13 L358,13 C355.243,13 353,15.243 353,18 L353,18 L353,19 L351,19 L351,18 C351,14.141 354.14,11 358,11 L358,11 Z M360,0 C362.757,0 365,2.24300003 365,5 C365,7.75699997 362.757,10 360,10 C357.243,10 355,7.75699997 355,5 C355,2.24300003 357.243,0 360,0 Z M360,2 C358.346,2 357,3.34599996 357,5 C357,6.65400004 358.346,8 360,8 C361.654,8 363,6.65400004 363,5 C363,3.34599996 361.654,2 360,2 Z"
          id="Account"
        ></path>
      </g>
    </g>
  </svg>
</template>
