import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

const store = createStore({
  state: {
    // apis
    sideMenuCollapsePattern: {},
    token: undefined,
    user: {},
    searchKey: '',
    currLab: {},
    product: {},
    frquentlyVisitedRoute: [],
    // //////////////
    // tv app
    // //////////////
    prod: {},
    currImage: '',
    cart: [],
    cartMap: {},
    isClinicOrVitalleo: false,
    toastMsg: {},
    permissions: {
      clinic: 27,
      clinic_orders: 29,
      doctor_npi_field: 31,
      qr_scanner: 30,
      vitalleo: 25,
      vitalleo_orders: 32,
      vitalleo_user: 28,
      patient_email: 33,
      return_tracking_field: 34,
      vitalleo_total: 36,
      clinic_total: 35,
    },
    isLoginModal: false,
    timeOutId: undefined,
  },
  mutations: {
    // /////////////////
    // tv app
    // /////////////////
    setProd(state, prod) {
      state.prod = prod;
      state.currImage = prod.images[0];
    },
    setCurrImage(state, image) {
      state.currImage = image;
    },
    setCart(state, cart) {
      state.cart.push(cart);
    },
    setCartMap(state, prodTitle) {
      state.cartMap = { ...state.cartMap, [prodTitle]: state.cart.length };
    },
    deleteCartItem({ cart, cartMap }, title) {
      const index = cart.findIndex((item) => item.title === title);
      console.log('index:', index);
      Vue.delete(cartMap, title);
      cart.splice(index, 1);
      cart.forEach((cartItem, index) => {
        Vue.set(cartMap, cartItem.title, index);
      });
      console.log('cartMap:', cartMap);
      console.log('cart:', cart);
    },
    // /////////////////
    // rx admin
    // /////////////////
    frquentlyVisitedRoute(state, payload) {
      const { title } = payload;
      const route = state.frquentlyVisitedRoute.find((route) => route.title === title);
      if (route) {
        route.visitedCount += 1;
      } else {
        state.frquentlyVisitedRoute.push(payload);
      }
      // sort by visitedCount
      state.frquentlyVisitedRoute.sort((rOne, rTwo) => rTwo.visitedCount - rOne.visitedCount);
    },
    setCollapsePattern(state, pattern) {
      state.sideMenuCollapsePattern[pattern.key] = pattern.isCollapsed;
    },
    setToken(state, token) {
      state.token = token;
    },
    saveUser(state, user) {
      state.user = user;
    },
    setSearchKey(state, searchKey) {
      state.searchKey = searchKey;
    },
    setCurrLab(state, currLab) {
      state.currLab = currLab;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setClincOrVitalleo(state, boolean) {
      state.isClinicOrVitalleo = boolean;
    },
    setToastMsg(state, message) {
      state.toastMsg = message;
      let secs = undefined;
      if (message.type === 'success') {
        secs = 2000;
      } else {
        secs = 7000;
      }
      state.timeOutId = setTimeout(() => {
        state.toastMsg = {};
        clearTimeout(state.timeOutId);
      }, secs);
    },
    removeToastMsg(state) {
      state.toastMsg = {};
    },
    setLoginModal(state, boolean) {
      state.isLoginModal = boolean;
    },
  },
  plugins: [vuexLocal.plugin],
});

export default store;
